import React from "react";
import ReactDOM from "react-dom/client";
import { OfficeRouter } from "./routes/OfficeRouter";
import { HelmetProvider } from "react-helmet-async";
import { AuthProvider } from "./hooks/useAuth";
import { ActivityFeedContextProvider } from "./hooks/ActivityFeedContext";
import { CenturionContextProvider } from "./hooks/CenturionContext";
import { PartnersContextProvider } from "./hooks/PartnersContext";
import { MemberContextProvider } from "./hooks/MemberContext";
import { ActionHubContextProvider } from "./contexts/ActionHubContext";
import { ActionableItemsProvider } from "./hooks/ActionableItemsContext";
import { DistributorContextProvider } from "./hooks/DistributorContext";
import { LanguageContextProvider } from "./hooks/LanguageContext";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import "./index.css";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { LanguageDirectionWrapper } from "./hooks/LanguageDirectionWrapper";
import { PopupContextProvider } from "./hooks/PopupContext";
import { SnackbarProvider } from './contexts/SnackbarContext';
import VersionChangeBanner from "./components/VersionChangeBanner";
import './services/translation/i18n';

import { ThemeProvider } from "./hooks/ThemeContext";
import { CssBaseline } from "@mui/material";

declare global {
  interface NodeModule {
    hot?: {
      accept(dependencies?: string | string[], callback?: () => void): void;
      decline(dependencies?: string | string[]): void;
      dispose(callback?: (data: any) => void): void;
      addDisposeHandler(callback: (data: any) => void): void;
      removeDisposeHandler(callback: (data: any) => void): void;
      status(): string;
    };
  }
}

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_LICENSE_KEY ?? "");

function OfficeApp() {
  return (
    <React.StrictMode>
      <SnackbarProvider>
        <LanguageContextProvider>
          <LanguageDirectionWrapper>
            <AuthProvider>
              <MemberContextProvider>
                <DistributorContextProvider>
                  <PartnersContextProvider>
                    <ActionHubContextProvider>
                      <ActionableItemsProvider>
                        <CenturionContextProvider>
                          <ActivityFeedContextProvider>
                            <HelmetProvider>
                              <PopupContextProvider>
                                <ThemeProvider>
                                  <CssBaseline />
                                  <VersionChangeBanner />
                                  <OfficeRouter />
                                </ThemeProvider>
                              </PopupContextProvider>
                            </HelmetProvider>
                          </ActivityFeedContextProvider>
                        </CenturionContextProvider>
                      </ActionableItemsProvider>
                    </ActionHubContextProvider>
                  </PartnersContextProvider>
                </DistributorContextProvider>
              </MemberContextProvider>
            </AuthProvider>
          </LanguageDirectionWrapper>
        </LanguageContextProvider>
      </SnackbarProvider>
    </React.StrictMode>
  );
}

const LDProvider = withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCHDARKLY_KEY ?? "",
  context: {
    kind: "user",
    key: process.env.REACT_APP_LAUNCHDARKLY_USER ?? "",
  },
  options: {
    /* ... */
  },
})(OfficeApp);

if (module.hot) {
  module.hot.accept();
}

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(<LDProvider />);
